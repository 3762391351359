import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import settingsreducer from './reducers/settings';
import api from "../store/Api/api";

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    settings: settingsreducer
})

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: [
        api
    ]
});

export const persistor = persistStore(store);