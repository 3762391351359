let config = {

  // default country set
  DefaultCountrySelectedInMobile: "in",
  // default language 
  DefaultLanguage: "en",

  //google Places key
  YOUR_GOOGLE_MAPS_API_KEY: "AIza2s1d52eTsnUMMG4SN6Agjz7JD3w_258645E",

};

export default config;
